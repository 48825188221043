<script setup>
import Container from "@/Components/Website/Container.vue";
import {computed, ref} from "vue";
import route from "@/Helpers/route";
import {Link, usePage} from "@inertiajs/vue3";

const page = usePage();

const props = defineProps({
    restaurant: Object,
});

const showExtraExceptions = ref(false);

const weekdays = {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
};

const destination = computed(() => {
    if (! props.restaurant.name || ! props.restaurant.address) {
        return '';
    }

    let dest = `${props.restaurant.name}, ${props.restaurant.address.street} ${props.restaurant.address.number}`;

    return encodeURIComponent(dest);
});

const timeGroups = computed(() => {
    let groups = [];

    groups.push({
        title: 'Openingstijden',
        times: props.restaurant.opening_times,
        exceptions: props.restaurant.opening_time_exceptions
    });

    if (props.restaurant.can_deliver) {
        groups.push({
            title: 'Bezorgtijden',
            times: props.restaurant.delivery_times,
            exceptions: props.restaurant.delivery_time_exceptions
        });
    }

    if (props.restaurant.can_pickup) {
        groups.push({
            title: 'Afhaaltijden',
            times: props.restaurant.pickup_times,
            exceptions: props.restaurant.pickup_time_exceptions
        });
    }

    return groups;
});
</script>

<template>
    <div class="bg-white shadow py-8" v-if="restaurant.has_website">
        <Container class="grid grid-cols-1 xl:grid-cols-3 gap-4">
            <div>
                <p class="text-xl font-medium mb-2">{{ restaurant.name }}</p>

                <div class="inline-flex flex-row items-center gap-2" v-if="restaurant.reviews_count > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-4 h-4 fill-yellow-400">
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                    </svg>

                    <span class="font-medium text-gray-700">{{ restaurant.stars ?? '0.0' }}</span>

                    <Link :href="route(`${page.props.routing_group}.reviews`, {[page.props.routing_key]: page.props.routing_target})" class="font-medium text-blue-500 ">
                        {{ restaurant.reviews_count }} beoordeling(en)
                    </Link>
                </div>

                <a :href="`mailto:${restaurant.email}`" class="flex flex-row gap-2 items-center mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="w-4 h-4">
                        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                    </svg>

                    <span>{{ restaurant.email }}</span>
                </a>

                <a :href="`tel:${restaurant.phone}`" class="flex flex-row gap-2 items-center mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="w-4 h-4">
                        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                    </svg>

                    <span>{{ restaurant.phone }}</span>
                </a>

                <a :href="`https://www.google.com/maps/dir/?api=1&destination=${destination}`" class="flex flex-row gap-2 items-center" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" class="w-4 h-4">
                        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M36.8 192H603.2c20.3 0 36.8-16.5 36.8-36.8c0-7.3-2.2-14.4-6.2-20.4L558.2 21.4C549.3 8 534.4 0 518.3 0H121.7c-16 0-31 8-39.9 21.4L6.2 134.7c-4 6.1-6.2 13.2-6.2 20.4C0 175.5 16.5 192 36.8 192zM64 224V384v80c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V384 224H320V384H128V224H64zm448 0V480c0 17.7 14.3 32 32 32s32-14.3 32-32V224H512z"/>
                    </svg>

                    <address class="not-italic">{{ restaurant.address.street }} {{ restaurant.address.number }} {{ restaurant.address.extra }}, {{ restaurant.address.zipcode }} {{ restaurant.address.city }}</address>
                </a>


                <template v-if="page.props.extra.footer">
                    <p class="text-xl font-medium mb-2 mt-8">Navigatie</p>

                    <ul class="list-disc list-inside">
                        <template v-for="link in page.props.extra.footer.nav_menu_items" :key="link.id">
                            <li>
                                <Link :href="link.url" class="text-blue-500" v-if="link.type === 'link' && !link.url.startsWith('http')">{{ link.text }}</Link>
                                <a :href="link.url" class="text-blue-500" v-if="link.type === 'link' && link.url.startsWith('http')" target="_blank">{{ link.text }}</a>
                            </li>
                        </template>
                    </ul>
                </template>

            </div>

            <div class="xl:col-span-2 grid grid-cols-1 xl:grid-cols-3 gap-4">
                <div v-for="group in timeGroups" :key="group.title">
                    <p class="text-xl font-medium mb-2">{{ group.title }}</p>
                    <table class="w-full" :class="{ 'mb-8': group.exceptions.length > 0 }">
                        <tbody>
                        <tr v-for="time in group.times" :key="time.id" :dusk="`time-${time.id}`">
                            <td class="py-1 pr-1" :class="{ 'font-bold text-[var(--primary)]': time.is_today, 'font-medium': ! time.is_today }">
                                {{ weekdays[time.weekday] }}
                            </td>
                            <td class="p-1" v-if="!time.is_closed" :class="{ 'font-bold text-[var(--primary)]': time.is_today }">
                                {{ time.opens_at?.formatted }} - {{ time.closes_at?.formatted }}
                            </td>
                            <td class="py-1 pl-1" v-if="time.is_closed" :class="{ 'font-bold text-[var(--primary)]': time.is_today }">
                                Gesloten
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <table class="w-full mb-2">
                        <tbody>
                        <tr v-for="(exception, index) in group.exceptions" :key="exception.id" :dusk="`exception-${exception.id}`" v-show="index < 3 || showExtraExceptions">
                            <td class="text-sm font-medium py-1 pr-1">
                                {{ exception.day.formatted }}
                            </td>
                            <td class="text-sm font-medium p-1">
                                <template v-if="! exception.is_closed">
                                    {{ exception.opens_at?.formatted }} - {{ exception.closes_at?.formatted }}
                                </template>
                                <template v-else>
                                    <span class="text-red-500">Gesloten</span>
                                </template>
                            </td>
                            <td class="text-sm py-1 pl-1">
                                {{ exception.description }}
                            </td>
                        </tr>
                        <tr v-if="timeGroups.find(group => group.exceptions.length > 3) && showExtraExceptions === false">
                            <td class="text-sm p-1" colspan="3">
                                {{ group.exceptions.length - 3 }} datums met afwijkende tijden.
                                <button class="xl:col-span-3 text-blue-500" @click="showExtraExceptions = true">
                                    Toon meer datums
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    </div>

    <div class="flex flex-row gap-4 justify-center bg-[var(--primary)] text-white font-medium text-center py-5">
        <a href="https://debestelapp.nl">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 145.7" xml:space="preserve" class="h-8">
                <g>
                    <path class="fill-white" d="M469.9,50.7c4,2.1,7.2,5,9.4,8.9c2.2,3.8,3.3,8.3,3.3,13.4s-1.1,9.5-3.3,13.3c-2.2,3.8-5.3,6.7-9.4,8.8 c-4,2.1-8.7,3.1-14,3.1h-17.7V47.6h17.7C461.2,47.6,465.9,48.7,469.9,50.7L469.9,50.7z M467.9,85.3c2.9-2.9,4.4-7,4.4-12.3 s-1.5-9.4-4.4-12.4s-7-4.5-12.3-4.5h-7.2v33.5h7.2C460.9,89.6,465,88.2,467.9,85.3L467.9,85.3z"/>
                    <path class="fill-white" d="M514.9,55.8v12.7h17v8.1h-17V90h19.2v8.3h-29.4V47.5h29.4v8.3H514.9L514.9,55.8z"/>
                    <path class="fill-white" d="M593.6,76.6c1.8,2.3,2.8,5,2.8,8c0,2.7-0.7,5.1-2,7.2s-3.2,3.7-5.8,4.8c-2.5,1.2-5.5,1.7-8.9,1.7h-21.8V47.6 h20.9c3.4,0,6.4,0.6,8.9,1.7c2.5,1.1,4.4,2.7,5.7,4.6c1.3,2,1.9,4.2,1.9,6.8c0,3-0.8,5.4-2.4,7.4s-3.7,3.4-6.3,4.2 C589.4,72.8,591.8,74.3,593.6,76.6L593.6,76.6z M568,68.5h9.3c2.4,0,4.3-0.5,5.6-1.6s2-2.7,2-4.7s-0.7-3.6-2-4.7 c-1.3-1.1-3.2-1.7-5.6-1.7H568V68.5z M584,88.3c1.4-1.2,2.1-2.8,2.1-4.9s-0.7-3.9-2.2-5.1s-3.4-1.8-5.9-1.8h-10V90h10.2 C580.7,90,582.6,89.4,584,88.3z"/>
                    <path class="fill-white" d="M629.2,55.8v12.7h17v8.1h-17V90h19.2v8.3H619V47.5h29.4v8.3H629.2L629.2,55.8z"/>
                    <path class="fill-white" d="M680.1,97c-2.8-1.2-5.1-2.9-6.7-5.2c-1.7-2.3-2.5-5-2.5-8.1h10.9c0.2,2.1,0.9,3.7,2.2,4.9 c1.3,1.2,3.2,1.8,5.5,1.8s4.2-0.6,5.6-1.7c1.3-1.1,2-2.6,2-4.5c0-1.5-0.5-2.7-1.4-3.7s-2.1-1.7-3.5-2.3s-3.3-1.2-5.7-1.8 c-3.3-1-6-1.9-8-2.9c-2.1-0.9-3.8-2.4-5.3-4.3s-2.2-4.5-2.2-7.7c0-3,0.8-5.6,2.2-7.8c1.5-2.2,3.6-3.9,6.3-5.1s5.8-1.8,9.3-1.8 c5.2,0,9.5,1.3,12.7,3.8s5.1,6.1,5.4,10.6h-11.2c-0.1-1.7-0.8-3.2-2.2-4.3s-3.2-1.7-5.5-1.7c-2,0-3.6,0.5-4.8,1.5s-1.8,2.5-1.8,4.4 c0,1.4,0.5,2.5,1.3,3.4c0.9,0.9,2,1.6,3.3,2.2s3.2,1.2,5.6,1.9c3.3,1,6,1.9,8.1,2.9s3.9,2.4,5.4,4.3s2.2,4.5,2.2,7.6 c0,2.7-0.7,5.2-2.1,7.6c-1.4,2.3-3.5,4.2-6.2,5.6s-5.9,2.1-9.7,2.1C686.1,98.8,682.9,98.2,680.1,97L680.1,97z"/>
                    <path class="fill-white" d="M765.3,47.6v8.2h-13.5v42.5h-10.2V55.8h-13.5v-8.2H765.3L765.3,47.6z"/>
                    <path class="fill-white" d="M797.4,55.8v12.7h17v8.1h-17V90h19.2v8.3h-29.4V47.5h29.4v8.3H797.4L797.4,55.8z"/>
                    <path class="fill-white" d="M850.5,90.2h16.7v8.1h-26.8V47.6h10.2v42.6H850.5z"/>
                    <path class="fill-white" d="M919.2,88.6H899l-3.3,9.7H885l18.2-50.7H915l18.2,50.7h-10.7L919.2,88.6L919.2,88.6z M916.5,80.5l-7.3-21.2 l-7.3,21.2H916.5L916.5,80.5z"/>
                    <path class="fill-white" d="M989.7,70.9c-1.3,2.4-3.3,4.3-6,5.7c-2.7,1.4-6.2,2.2-10.3,2.2h-8.5v19.4h-10.2V47.6h18.7c3.9,0,7.3,0.7,10,2 c2.8,1.4,4.8,3.2,6.2,5.6c1.4,2.4,2.1,5.1,2.1,8.1C991.6,66,991,68.6,989.7,70.9L989.7,70.9z M979.1,68.7c1.3-1.3,2-3.1,2-5.4 c0-4.9-2.8-7.4-8.3-7.4h-8.1v14.7h8.1C975.7,70.6,977.8,70,979.1,68.7z"/>
                    <path class="fill-white" d="M1048.3,70.9c-1.3,2.4-3.3,4.3-6,5.7c-2.7,1.4-6.2,2.2-10.3,2.2h-8.5v19.4h-10.2V47.6h18.6c3.9,0,7.3,0.7,10,2 c2.8,1.4,4.8,3.2,6.2,5.6c1.4,2.4,2.1,5.1,2.1,8.1C1050.2,66,1049.6,68.6,1048.3,70.9L1048.3,70.9z M1037.7,68.7 c1.3-1.3,2-3.1,2-5.4c0-4.9-2.8-7.4-8.3-7.4h-8.1v14.7h8.1C1034.3,70.6,1036.4,70,1037.7,68.7z"/>
                    <path class="fill-white" d="M1071.9,97.1c-1.2-1.1-1.8-2.6-1.8-4.2s0.6-3.1,1.8-4.2c1.2-1.1,2.7-1.7,4.5-1.7s3.3,0.6,4.4,1.7 s1.7,2.6,1.7,4.2s-0.6,3.1-1.7,4.2s-2.6,1.7-4.4,1.7C1074.6,98.8,1073.1,98.2,1071.9,97.1z"/>
                    <path class="fill-white" d="M1148.6,98.3h-10.2l-23-34.8v34.8h-10.2V47.5h10.2l23,34.8V47.5h10.2V98.3z"/>
                    <path class="fill-white" d="M1183.3,90.2h16.7v8.1h-26.8V47.6h10.2L1183.3,90.2L1183.3,90.2z"/>
                </g>

                <path class="fill-white" d="M380.6,124.4L361,14.9C359.5,6.3,351.9,0,343.2,0h-38.9c-8.8,0-16.3,6.3-17.8,14.9L263,145.7h55 c0-0.2,0-0.4,0-0.7c0.6-9.8-3.5-13.9-5.9-15.4c-1.1-0.7-1.8-1.9-1.9-3.1c-7.2-72.5,5.3-99.7,17.6-99.7c3.1,0,4.2,2.1,4.2,4.7v114.2 h30.6C374,145.7,382.6,135.5,380.6,124.4L380.6,124.4z"/>
                <path class="fill-white" d="M64.7,0H18.1C8.1,0,0,8.1,0,18.1v127.6h49.4c1.2-25.9-0.8-33.8-8.1-42.8l0,0c-3.4-4.2-5.6-10.1-5.2-16.2 l2.4-37.9l1.3-21.4c0-0.7,0.6-1.2,1.3-1.2s1.2,0.6,1.2,1.3l1.5,43.9c0,1,0.9,1.8,1.9,1.8s1.8-0.8,1.8-1.8l0.2-43.8 c0-0.9,0.9-1.6,1.9-1.2c0.5,0.2,0.8,0.7,0.8,1.2L52.3,72c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2l1.9-44.4c0-0.9,1-1.6,1.9-1.2 c0.5,0.2,0.8,0.7,0.8,1.2l0.2,43.8c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.9-1.8l1.5-43.9c0-0.7,0.7-1.3,1.4-1.2c0.6,0.1,1.1,0.6,1.1,1.2 l1.3,21.4l2.4,37.9c0.4,6.1-1.8,11.9-5.2,16.2l0,0c-7.3,9.1-9.3,16.9-8.1,42.8h7.2c40.2,0,56.7-32.6,56.7-72.8l0,0 C121.4,32.6,105,0,64.7,0z"/>
                <path class="fill-white" d="M255.8,40.5L255.8,40.5c0-22.4-18.1-40.5-40.5-40.5h-62.8c-10,0-18.1,8.1-18.1,18.1v127.6h47 c1.5-30.2-0.7-39.1-9-49.4h0.1c-5.9-7-9.7-18.3-9.7-31.1c0-21.2,10.4-38.4,23.3-38.4s23.3,17.2,23.3,38.4c0,12.8-3.8,24.1-9.7,31.1 h0.1c-8.4,10.3-10.6,19.3-9,49.4h24.6c22.4,0,40.5-18.1,40.5-40.5l0,0c0-13.2-6.4-25-16.2-32.4C249.5,65.5,255.8,53.7,255.8,40.5 L255.8,40.5z"/>
            </svg>

            <span class="sr-only">De Bestel App Homepagina</span>
        </a>
    </div>
</template>
